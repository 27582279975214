import Vue from "vue";

Vue.component("card", require("./components/Card.vue").default);
Vue.component("mobile-menu", require("./components/MobileMenu.vue").default);
Vue.component("mobile-menu-icon", require("./components/MobileMenuIcon.vue").default);

new Vue({
	el: "#app",
	mounted() {
		this.$root.$on("stopScrolling", () => {
			document.body.classList.add("has--no-scrolling");
		});

		this.$root.$on("resumeScrolling", () => {
			document.body.classList.remove("has--no-scrolling");
		});
	},
	computed: {
		sortedItems2022() {
			return this.items[2022].slice().sort(function (a, b) {
				return a.artist.toLocaleLowerCase() > b.artist.toLocaleLowerCase() ? 1 : -1;
			});
		},
		sortedItems() {
			return this.items[2023].slice().sort(function () {
				return 0.5 - Math.random();
			});
		},
	},
	data: {
		items: {
			2023: [
				{
					id: 1,
					img: "/assets/img/2023/OTS_AdamTerry.jpg",
					headline: "On the sheet",
					artist: "Adam Terry",
					content:
						"Adam Terry is a London-based freelance designer & artworker, specialising in key art for entertainment. Adam’s commissioned work currently focuses on photo manipulation, but his film & TV fan art also includes minimalist illustration, some of which was shortlisted in a Focus Features Talenthouse competition.<br><br> In 2022, Adam was featured in the Poster Posse Protege programme to celebrate the 35th of The Lost Boys. His clients include Viaplay, AMC Networks and Studio Soho amongst others.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/adamterrydesigns/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/adamterry1986",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/camartinart",
						},
						{
							name: "website",
							link: "https://www.adamterrydesigns.com/work/filmandtv",
						},
					],
				},
				{
					id: 2,
					img: "/assets/img/2023/OTS_ADNz.jpg",
					headline: "On the sheet",
					artist: "Adrien Noterdaem",
					content:
						"Adrien Noterdaem is a creative designer and illustrator from Brussels, Belgium. He’s working on various offline and online projects as well as storyboards for advertising or short films. His creative spectrum is fairly wide, the treat being to vary the pleasures. He is passionate about pop culture and sometimes loves to draw little yellow #simpsonized characters ;)",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/adnz",
						},
						{
							name: "twitter",
							link: "https://twitter.com/ADNz",
						},
						{
							name: "linktree",
							link: "https://linktr.ee/adnz",
						},
					],
				},
				{
					id: 3,
					img: "/assets/img/2023/OTS_AgustinRMichel.jpg",
					headline: "On the sheet",
					artist: "Agustín R. Michel",
					content:
						"Agustín R. Michel is a Mexican graphic designer. He has collaborated with important national and international brands, and regardless of the nature of the project, his sensitivity and attention to detail are always elements to highlight.<br><br>As a film buff, one of his main passions is designing movie posters. He was a finalist at the 16th International Poster Biennale in Mexico in the film poster category. His work has been featured on projects like PosterSpy’s ‘Mandy: The Ultimate Poster Collection’ and Umbrella Entertainment’s special blu-ray edition of Pascal Laugier’s ‘Martyrs’.<br><br>His creative process is characterized by curiosity and constant research.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/agustinrmichel/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/AgustinrMichel",
						},
						{
							name: "website",
							link: "http://www.agustinrmichel.com",
						},
					],
				},
				{
					id: 4,
					img: "/assets/img/2023/OTS_Alecxps.jpg",
					headline: "On the sheet",
					artist: "Alecxps Design",
					content:
						"Alecxps Design is the unpronounceable artistic name of Alex Hinojosa, a graphic designer from Barcelona, Spain. Even though he studied biomedical engineering, Alex started playing with Adobe Photoshop when he was 14, focusing mainly on movie posters. Among his different styles, he loves to make grainy and gritty retro compositions for horror films. His clients include Greg Sestero, GrandSon (A24), OOG Creative (Netflix, UFC), Leroy & Rose (Sky) and many indie filmmakers.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/alecxpsdesign/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/alecxpsdesign",
						},
						{
							name: "website",
							link: "https://alecxpsdesign.com",
						},
					],
				},
				{
					id: 5,
					img: "/assets/img/2023/OTS_AlexanderKaufmann.jpg",
					headline: "On the sheet",
					artist: "Alexander Kaufmann",
					content:
						"Alexander Kaufmann is a European American freelance digital artist and designer from the United States, focusing on key art and alternative art inspired by film, TV, video games, and music. His style ranges from realistic photo manipulation to conceptual minimalism illustration-based work. He is always eager to improve his artwork and try new styles and techniques. His art has won multiple awards such as the “American Advertisement Awards”, Marvin S. Cone Art Show”, and “IRSC's 20th Annual Digital Media Exhibition”.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/djanoidgfx",
						},
						{
							name: "twitter",
							link: "https://twitter.com/DjANOIDgfx",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/djanoidgfx",
						},
						{
							name: "website",
							link: "https://jakaufmann.myportfolio.com/",
						},
					],
				},
				{
					id: 6,
					img: "/assets/img/2023/OTS_AlexisVeille.jpg",
					headline: "On the sheet",
					artist: "Alexis Veille",
					content:
						"Alexis Veille is a french graphic designer, focused on indie film posters. He’s self-taught, loves tinkering with lights, contrasts and textures to give his posters some “organic feels”. In 2022, his clients included several short film directors as well as some film distribution companies such as UFO Distribution (Junk Head), Jokers Films (press kit for photography book Rebels) and Utopia (promotional video for Gaspar Noé’s Vortex).",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/alexis.veille/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/AlexisVeille",
						},
						{
							name: "website",
							link: "http://alexis-veille.com/",
						},
					],
				},
				{
					id: 7,
					img: "/assets/img/2023/OTS_AminMashayekhi.jpg",
					headline: "On the sheet",
					artist: "Amin Mashayekhi",
					content:
						"Amin Mashayekhi (AminDesigns) is a Graphic Designer based in Canada. He has a strong passion for minimalist work and for the past few years, he has gotten into the alternative movie poster space, mainly creating posters for his favourite films and shows. Most of his work includes singular or minimal imagery and icons, which capture the essence and meaning of a film/show.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/aminmdesigns",
						},
						{
							name: "twitter",
							link: "https://twitter.com/amindesigns",
						},
						{
							name: "website",
							link: "https://www.amindesigns.ca/",
						},
					],
				},
				{
					id: 8,
					img: "/assets/img/2023/OTS_AndreBarnett.jpg",
					headline: "On the sheet",
					artist: "André Barnett",
					content:
						"André Barnett is a retired graphic designer reinventing himself as an illustrator. His latest work is mainly alternative movie posters.  Over the past few years, he has developed a vector graphic style using a crosshatch technique.  In the future, he plans to create a series of figurative works still using his digital techniques, allowing his fine art experience to influence the final outcome.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/onlychildart",
						},
						{
							name: "twitter",
							link: "https://www.twitter.com/onlychildart",
						},
						{
							name: "website",
							link: "http://andrebarnett.myportfolio.com",
						},
					],
				},
				{
					id: 9,
					img: "/assets/img/2023/OTS_AntoninoDeStefano.jpg",
					headline: "On the sheet",
					artist: "Antonino De Stefano",
					content:
						"Antonino De Stefano is a designer and illustrator from Rome, Italy, where he designs digital interfaces and graphic assets. He enjoys creating space missions and alternative movie posters. He communicates concepts of his artworks through different styles, from minimalist illustrations to vintage photographic ones. His works include also children's illustrations and cute characters.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/anto_deste1/",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/antonino.destefano/",
						},
						{
							name: "website",
							link: "https://antodestefano.myportfolio.com/",
						},
					],
				},
				{
					id: 10,
					img: "/assets/img/2023/OTS_AudreyEstok.jpg",
					headline: "On the sheet",
					artist: "Audrey Estok",
					content:
						"Audrey Estok, a Philadelphia native, attended the Maryland Institute College of Art, where she majored in illustration with a concentration in book arts. With a strong community of artists and like-minded people, she has chosen to make Baltimore home. Her art defies categorization; the graphic but dynamic illustrations inspire curiosity and stands apart from any singular moment or movement to achieve a timeless quality. Her illustrations explore the interactions of different colors, pushing what colors work together in unexpected ways. Audrey’s art has been featured on several book covers and in a number of publications, her past clients include Disney, Sony Music, Forbes Magazine and Bloomsbury Publishing and Macmillan Publishers.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/audreyestok/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/AudreyEstok",
						},
					],
				},
				{
					id: 11,
					img: "/assets/img/2023/OTS_Azmat.jpg",
					headline: "On the sheet",
					artist: "Azmat",
					content:
						"Azmat is a digital artist based in India, Mainly focusing on Poster/Key art, Environment concept arts, Book cover arts and all things illustrations. His style can be described as Surrealism, conceptual. He has worked on key arts for various feature films.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/visualsofazmat.eth/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Visualsofazmat1",
						},
						{
							name: "website",
							link: "https://www.artstation.com/visualsofazmat",
						},
					],
				},
				{
					id: 12,
					img: "/assets/img/2023/OTS_BailieRosenlund.jpg",
					headline: "On the sheet",
					artist: "Bailie Rosenlund",
					content:
						"Bailie is an award-winning illustrator; comic artist; and designer in animation. Through her work, she likes to explore the relationship between women and gothic fantasy. Using mood, lighting, and composition, she tells stories by the feelings her work evokes. When Bailie is not drawing, she can be found playing a game, reading a book, or taking a nap.",
					links: [
						{
							name: "instagram",
							link: "http://www.instagram.com/bailierosenlund",
						},
						{
							name: "twitter",
							link: "http://www.twitter.com/bailierosenlund",
						},
						{
							name: "website",
							link: "http://www.bailierosenlund.com",
						},
					],
				},
				{
					id: 13,
					img: "/assets/img/2023/OTS_BernardGagliardi.jpg",
					headline: "On the sheet",
					artist: "Bernard William “Billy” Gagliardi",
					content:
						"Bernard William “Billy” Gagliardi is a graphic designer from California who designs posters, illustrations, and logos to promote film and businesses. He specializes in photography, color correcting and photo manipulation to design posters. His illustrations are best described as block illustrations with outlines and shadows surrounding his subjects with the illusion of depth. He works with local filmmakers creating theatrical posters for films such as ABOVE SNAKES and LOST OUTLAW.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/bgagliardi92/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/BGagliardi31",
						},
						{
							name: "website",
							link: "https://www.bwgagliardi.myportfolio.com",
						},
					],
				},
				{
					id: 14,
					img: "/assets/img/2023/OTS_CharlieGould.jpg",
					headline: "On the sheet",
					artist: "Charlie Gould",
					content:
						"Charlie Gould is UK-based graphic image maker and designer that specializes in poster design and printmaking. His work draws large inspiration from popular culture, typography, traditional print processes, and alternative movie posters.<br><br>Charlie’s work makes heavy use of halftone and texture, limited colour palettes, letterpress, and collage, and has worked for clients including Warner Music, Red Light Management, and Merch. Traffic previously.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/charlieslgould/?hl=en",
						},
						{
							name: "twitter",
							link: "https://twitter.com/charliesIgould",
						},
						{
							name: "website",
							link: "https://www.charlie-gould.com/",
						},
					],
				},
				{
					id: 15,
					img: "/assets/img/2023/OTS_ChristinaJoerres.jpg",
					headline: "On the sheet",
					artist: "Christina Jörres",
					content:
						"Christina Jörres is a graphic and motion designer from Germany. In her free-time she is inspired by movies and private D&D sessions, and draws her favourite characters with a focus on their current situations and emotions.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/beerenjoe/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/beerenjoe",
						},
					],
				},
				{
					id: 16,
					img: "/assets/img/2023/OTS_CourtneyAutumnMartin.jpg",
					headline: "On the sheet",
					artist: "Courtney Autumn Martin",
					content:
						"Courtney Autumn Martin is a freelance illustrator living in Rhode Island. She graduated from the Rhode Island School of Design (RISD) with a degree in illustration and has worked as an in-house graphic designer, a fine art painter, and a children's illustrator. She currently works as a member of The Poster Posse, illustrating pop culture movie posters, portraits, and cover art for clients including Disney, Marvel Studios, Sony Pictures, and Adobe.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/camartinart",
						},
						{
							name: "twitter",
							link: "https://twitter.com/camartinart",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/camartinart",
						},
					],
				},
				{
					id: 17,
					img: "/assets/img/2023/OTS_DanStrange.jpg",
					headline: "On the sheet",
					artist: "Dan Strange",
					content:
						"Dan Strange is a London based artist/illustrator/designer. Working predominantly digitally he aims mainly to give a contemporary twist to classic Illustration & Design. Expect plenty of sly film references, a whole lotta pulp, an exceedingly unhealthy amount of musical inspiration and then just about any other irreverent pop culture reference that strikes him as generally quite amusing at the time.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/thinkstrange/",
						},
						{
							name: "twitter",
							link: "https://www.twitter.com/damnstrange",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/thinkstrange.illustration",
						},
						{
							name: "website",
							link: "https://www.thinkstrange.co.uk",
						},
					],
				},
				{
					id: 18,
					img: "/assets/img/2023/OTS_DaveKennedy.jpg",
					headline: "On the sheet",
					artist: "Dave Kennedy",
					content:
						"Dave Kennedy is a UK-based freelance artist and illustrator, currently working as a comic artist with Storm King Comics and the mighty John Carpenter. After many years working in graphic design and videogame marketing, he decided to concentrate on the aspect of his work he enjoys the most – drawing – be that comics, illustration, concept art, storyboarding, poster art, private commissions or whatever lands on his desk.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/cherrysheriff/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/cherrysheriff",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/cherrysheriffart",
						},
						{
							name: "website",
							link: "https://cherrysheriff.com/",
						},
					],
				},
				{
					id: 19,
					img: "/assets/img/2023/OTS_DorianPironneau.jpg",
					headline: "On the sheet",
					artist: "Dorian Pironneau",
					content:
						"Dorian Pironneau is a french graphic designer and art director based in Paris. He works with lettering, typography and photomontages to create visuals for the music and cinema fields. He loves enigmatic and otherworldly pieces of art. His clients have been bands like Amenra and Cult of Luna, and short film directors like Laurens Saint Gaudens and Diogo Guerreiro.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/doriantwp/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/doriantwp",
						},
						{
							name: "website",
							link: "https://www.dorianpironneau.com",
						},
					],
				},
				{
					id: 20,
					img: "/assets/img/2023/OTS_DorotheaTaylor.jpg",
					headline: "On the sheet",
					artist: "Dorothea Taylor",
					content:
						"Dorothea Taylor is a “designustrator” from Atlanta, Georgia. She is a self-proclaimed pop culutre enthusist and a big fan of films and music. Art Deco is one of her earliest inspirations and she loves to create illustrative images with interesting color schemes and thoughtful composition. Her company, T.P. Design, has worked with countless consumer goods clients including Coke, Pepsi, General Mills and Georgia Pacific creating packaging, surface design and point-of-sale. As a member of the Poster Posse, she has created images for clients like Disney, Marvel, Pixar, LucasFilms and Universal Pictures.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/tpdesigninc",
						},
						{
							name: "twitter",
							link: "https://twitter.com/tpdesigninc",
						},
						{
							name: "website",
							link: "https://www.tpdesigninc.com",
						},
					],
				},
				{
					id: 21,
					img: "/assets/img/2023/OTS_EdgarAscensao.jpg",
					headline: "On the sheet",
					artist: "Edgar Ascensão",
					content:
						"Edgar Ascensão is a poster artist living in Lisbon, Portugal. Movies and illustration art is his biggest passion and love to create art for both. He loves to create art with a different point of view of the movie which can provide a more conceptual perspective.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/edgar_asc/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/edgar_ascensao",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/posterscaseiros/",
						},
						{
							name: "linktree",
							link: "https://linktr.ee/edgarascensao",
						},
					],
				},
				{
					id: 22,
					img: "/assets/img/2023/OTS_EileenSteinbach.jpg",
					headline: "On the sheet",
					artist: "Eileen Steinbach",
					content:
						"Eileen Steinbach is a designer and illustrator from Germany, focusing on key art as well as alternative art to promote films from indie pieces to studio productions. Her style can be described as conceptual minimalism, with strong and evocative ideas being the main focus of her both illustrative and image-based work. Her clients include Disney, Marvel, Pixar, Netflix, 20th Century Studios, Sony Pictures, Shudder, and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/sg_posters",
						},
						{
							name: "twitter",
							link: "https://twitter.com/SG_Posters",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/sgposters/",
						},
						{
							name: "website",
							link: "https://www.sg-posters.com",
						},
					],
				},
				{
					id: 23,
					img: "/assets/img/2023/OTS_ScottMichaelGallusness.jpg",
					headline: "On the sheet",
					artist: "Gallusness",
					content:
						"Gallusness, a.k.a. Scott MacMichael is a designer from Scotland. He works primarily with charity and cultural clients. His passion for film, dance and theatre help to inform an output that typically features and focuses on concepts, key art, title treatments, branding and craft. Clients include: NIKE, Ubisoft, Popcorn Storm, Holly Blakey, Vivienne Westwood, Aretha Franklin, Foo Fighters, Miles Davis, Manic Street Preachers, The Clash, CHVRCHES and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/gallusposters/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Gallusnews",
						},
						{
							name: "linkedin",
							link: "https://www.linkedin.com/in/scott-macmichael-ga11u5ne55/",
						},
						{
							name: "website",
							link: "https://gallusness.com/",
						},
					],
				},
				{
					id: 24,
					img: "/assets/img/2023/OTS_Royalston.jpg",
					headline: "On the sheet",
					artist: "Royalston",
					content:
						"Royalston, is a graphic artist and designer based in London. He leans closer to realism but remains Experimental when approaching new concepts and compositions. He’s influenced by comic books, movies and where possible will try and crowbar clouds into pretty much everything he paints.<br><br>He has had work exhibited through Moor Art Gallery, Bottleneck Gallery and Gallery 1988. He appeared in AMP's 30X30 show in 2021 and recently appeared in the ‘Film On Canvas’ artbook.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/royalstondesign",
						},
						{
							name: "twitter",
							link: "https://www.twitter.com/royalstondesign",
						},
						{
							name: "website",
							link: "https://www.royalstondesign.com",
						},
					],
				},
				{
					id: 25,
					img: "/assets/img/2023/OTS_GretaLeonaKammerer.jpg",
					headline: "On the sheet",
					artist: "Greta Leona Kammerer",
					content:
						"Greta Leona Kammerer is a graphic design student based in Freiburg, Germany. During her studies she discovered her passion for 3D art and typography and is thriving to explore every part of the design world. After her current senior year she is looking forward to setting foot in the design world and take part in new projects and challenges.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/leonagretadesign/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/leonagretaart",
						},
					],
				},
				{
					id: 26,
					img: "/assets/img/2023/OTS_HannahGillingham.jpg",
					headline: "On the sheet",
					artist: "Hannah Gillingham",
					content:
						"Hannah Gillingham is a UK based freelance digital illustrator and artist, working with a conceptual approach to illustration, creating painterly portraits and artwork exploring narrative in film and digital illustration. Her work has a revitalised take on digital painting, recreating traditional painting techniques digitally. Her clients include Spotify, Moor-Art Gallery, The University of Westminster, The University of Leicester, and more. ",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/hannahgillinghamm/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Hgillinghamm",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/hannahgillinghamm",
						},
						{
							name: "website",
							link: "https://www.hannahgillingham.com",
						},
					],
				},
				{
					id: 27,
					img: "/assets/img/2023/OTS_VenturePictures.jpg",
					headline: "On the sheet",
					artist: "James Young (Venture Pictures)",
					content:
						"James Young (Venture Pictures) is a UK-based self-taught digital artist who utilises photoshop to create fan art and alternative posters. Star Wars, Marvel, sci-fi, fantasy and pop culture influence much of his work. James also does commission work and collaborates with artists from across the communities he’s interested in on art pieces.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/venture_pictures",
						},
						{
							name: "twitter",
							link: "https://twitter.com/venturepictures ",
						},
					],
				},
				{
					id: 28,
					img: "/assets/img/2023/OTS_JamesHobson.jpg",
					headline: "On the sheet",
					artist: "James Hobson",
					content:
						"James Hobson is an illustrator and graphic designer working with a focus on pop culture and entertainment.  As a freelancer, he has contributed to campaigns for Netflix, Sony Playstation, Hulu, Disney, indie filmmakers and more.<br><br>As well as creating key art for print, out of home and online marketing graphics, James also enjoys designing alternative movie posters under the moniker ‘JamesJamesJames’.  He was recently exhibited in San Francisco and New York Comic Con for Spoke-Art Gallery.<br><br>James is co-host of a fortnightly podcast on the ‘Drop’ network, where he discusses and celebrates the latest alternative posters and key art.  He is co-owner and Art Director of Moor-Art Gallery, working with globally renowned artists to create limited edition, officially licensed premium art prints and posters for brands such as SEGA and Hasbro.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/jamesjamesjamescreative",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/jamesjamesjamescreative/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/_jamesx3",
						},
						{
							name: "linkedin",
							link: "https://www.linkedin.com/in/james-hobson-328545164",
						},
						{
							name: "website",
							link: "https://www.jamesjamesjames.co.uk",
						},
					],
				},
				{
					id: 29,
					img: "/assets/img/2023/OTS_JoshSpicer.jpg",
					headline: "On the sheet",
					artist: "Josh Spicer",
					content:
						"Josh Spicer is an Art Director from Calgary Alberta, his passion for filmmaking and creating short films has landed him a number of awards for his efforts and led him down the path of key art and alternative movie poster design. His design style is punk rock chic with an attention to controlled chaos. You can find him on his website",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/joshwrb",
						},
						{
							name: "twitter",
							link: "https://twitter.com/joshwrb",
						},
						{
							name: "website",
							link: "http://joshwrb.com",
						},
						{
							name: "youtube",
							link: "https://youtube.com/joshwrb",
						},
					],
				},
				{
					id: 30,
					img: "/assets/img/2023/OTS_KevinBravo.jpg",
					headline: "On the sheet",
					artist: "Kevin Bravo",
					content:
						"Kevin Bravo is a visual artist born and raised in Mexico. His passion for movies has made him want to share his love for the medium through art. He combines his background in graphic design and plastic arts to convey striking and clean concepts, changing his style to suit the project he's currently working on.<br><br>His works has been featured on projects like Printed in Blood´s “Hannibal: Artbook”, and 2022 AMP´s “30X30 Show”.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/thatkevinbravo/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/thatkevinbravo",
						},
						{
							name: "website",
							link: "https://www.behance.net/kbchavandocac2",
						},
					],
				},
				{
					id: 31,
					img: "/assets/img/2023/OTS_KevinTiernan.jpg",
					headline: "On the sheet",
					artist: "Kevin Tiernan",
					content:
						"Kevin Tiernan is a full-time art director and illustrator based out of New Haven, CT. As a kid, he covered his notebooks with doodles of pop culture characters, which is still true today. Kevin started creating fan art as a creative outlet and gained experience designing official work for WWE, OZARK, Rick & Morty, Halloween, Disney, and Gallery1988. He enjoys playing guitar, collecting records, hiking, questing for the best burritos and hanging out with his pugs. You can typically find him with a cup of coffee nearby.",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/jurassickevin",
						},
						{
							name: "twitter",
							link: "https://twitter.com/@jurassickevin",
						},
						{
							name: "tiktok",
							link: "https://www.tiktok.com/@jurassickevinart",
						},
						{
							name: "website",
							link: "https://tiernandesign.com",
						},
					],
				},
				{
					id: 32,
					img: "/assets/img/2023/OTS_LauraEmilieBeranrd.jpg",
					headline: "On the sheet",
					artist: "Laura Emilie Beranrd",
					content:
						"Laura is a freelance designer based near London. Self-taught draughtsman from a young age, she applied to the Condé School with the dream to become Poster Artist. She graduated and kicked off her career as a Graphic Designer in a Design agency in Nice. Then jumped into IT as a UI/UX Designer in England for some years before returning to her true passion: Graphic Art and Movies.",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/lauraemiliebernard",
						},
						{
							name: "twitter",
							link: "https://twitter.com/berndraw",
						},
						{
							name: "website",
							link: "https://lauraemiliebernard.com/",
						},
					],
				},
				{
					id: 33,
					img: "/assets/img/2023/OTS_LittleMissChickFlick.jpg",
					headline: "On the sheet",
					artist: "Little Miss Chick Flick",
					content:
						"Little Miss Chick Flick, online alias of Rafke, is a Dutch frontend developer and designer. She graduated in 2022 from her studies Communication and Multimedia Design, where she learned to combine conceptual technology with design. In 2018 she started a blog about films, where she would write about inspirational films and other film news. Her blog is where she learned she could combine her two passions: designing and films. In her free time, she enjoys making film posters, most of them in a minimalistic style. Although a novice, she aspires to be a professional graphic designer in the film business somewhere in the future.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/littlemisschickflick/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/LMCFblog",
						},
						{
							name: "youtube",
							link: "https://www.youtube.com/littlemisschickflick",
						},
						{
							name: "website",
							link: "Little Miss Chick Flick",
						},
					],
				},
				{
					id: 34,
					img: "/assets/img/2023/OTS_LizaShumska.jpg",
					headline: "On the sheet",
					artist: "Liza Shumska",
					content:
						"Liza (aka Kino Maniac) is a professional illustrator and poster artist for the past 6 years. She was born and currently based in Kyiv, Ukraine. Liza is passionate about cinema and music from which she draws inspiration for her works. Creating posters is a great way to combine all the things that she enjoys so much. It’s a unique kind of art, which can tell you a story without a single word, to convey the mood and atmosphere. She’s had the chance to work on exciting projects for film studios, music bands, game studios and publishers. Her client list includes: Disney, 20th Century Studios, Marvel, Scorpions, HBO, Bethesda and many more. She likes to experiment with the different techniques and styles in her illustrations which gives her artistic freedom.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/kino_maniac/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Liza_Shumska",
						},
						{
							name: "website",
							link: "https://lizashumskayaart.myportfolio.com/work",
						},
					],
				},
				{
					id: 35,
					img: "/assets/img/2023/OTS_LucasTetrault.jpg",
					headline: "On the sheet",
					artist: "Lucas Tetrault",
					content:
						"When not working as the Creative Director for Intersection, Lucas spends much of his available free time, between the hours of 10 pm to 2 am, creating alternative movie posters and art for galleries and publications such as Gallery 1988, Hero Complex, Printed in Blood and Netflix.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/creativebylucas",
						},
						{
							name: "twitter",
							link: "https://twitter.com/creativebylucas",
						},
					],
				},
				{
					id: 36,
					img: "/assets/img/2023/OTS_MarcTOuellette.jpg",
					headline: "On the sheet",
					artist: "Marc T. Ouellette",
					content:
						"Marc T. Ouellette is a Canadian graphic designer and illustrator, focused on key art, alternative movie posters and gig art. His comic influenced, highly detailed illustration style is complemented by a highly practiced eye for layout & typography, and an uncanny ease with the tools of the trade. His clients have included McDonald’s, Google, Nissan and Canadian Tire and his work was featured as part of this year's AMP 30x30 1992.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/marctouellette",
						},
						{
							name: "twitter",
							link: "https://twitter.com/marctouellette",
						},
						{
							name: "website",
							link: "https://www.marctouellette.com",
						},
					],
				},
				{
					id: 37,
					img: "/assets/img/2023/OTS_MariaKu.jpg",
					headline: "On the sheet",
					artist: "Maria Ku",
					content:
						"Maria Ku is a graphic designer and illustrator from Minneapolis, USA (originally Moscow, Russia) with a passion for all things pop culture. Her work is a blend of illustration and lettering, with limited color palettes and a preference for stylization over realism. Maria’s clients include Supermassive Games, The Washington Post, In These Times, the Irish Film Festival in Russia, and VICE. Her work has also been published in several Printed in Blood artbooks.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/grrrenadine",
						},
						{
							name: "twitter",
							link: "https://twitter.com/weird_werewolf",
						},
						{
							name: "website",
							link: "https://www.grrrenadine.com/",
						},
					],
				},
				{
					id: 38,
					img: "/assets/img/2023/OTS_MarkLevy.jpg",
					headline: "On the sheet",
					artist: "Mark Levy",
					content:
						"Mark Levy is a UK based digital illustrator with over 25 years of experience. Trained in oils on canvas at Falmouth College of Arts, Mark has worked across a wide range of fields including designing arcade machines, TV animation, web design and motion graphics. But his love of film and illustration drew him back to what he loves most and he now focuses on poster art and illustration digitally on the Mac and more recently iPad. As well as commissions for editorial illustrations and movie posters, Mark's work has featured in a number of books including “Stranger Things Artbook“, “Aliens Artbook“, “John Carpenter's Halloween Artbook“, “Underexposed - The 50 Greatest Movies Never Made“ and “Film on Canvas - If Fine Artists Made Movie Posters”. His work has also been exhibited at Gallery 1988, Poster Spy, Stroud Film Festival Exhibition and Moore ArtGallery.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/MarkLevyArt",
						},
						{
							name: "twitter",
							link: "https://twitter.com/MarkLevyArt",
						},
						{
							name: "linkedin",
							link: "https://www.linkedin.com/in/MarkLevyArt/",
						},
						{
							name: "website",
							link: "https://www.marklevyart.com/",
						},
					],
				},
				{
					id: 39,
					img: "/assets/img/2023/OTS_designomatt.jpg",
					headline: "On the sheet",
					artist: "Matthew Gallagher",
					content:
						"Matthew Gallagher is a traditionally trained artist who has worked commercially over the course of his career for companies such as: The Walt Disney Company, Hallmark, IBM, Travel + Leisure and more. His style combines art materials with digital tools to replicate traditionally, hand-made illustrations. While his work has been licensed for such properties as: Aliens, Halloween, and Hannibal, he continues his passions which are focused on pop-cutter icons and modern mythologies.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/designomatt/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/designomatt",
						},
						{
							name: "website",
							link: "http://matthewcgallagher.com",
						},
					],
				},
				{
					id: 40,
					img: "/assets/img/2023/OTS_MelissaShipley.jpg",
					headline: "On the sheet",
					artist: "Melissa Shipley",
					content:
						"Melissa Shipley is a freelance designer and illustrator located just outside of Baltimore, Maryland. She specializes in creating visuals through both digital illustration and photo manipulation for a broad range of clients. As a lifelong film enthusiast, she mixes her love of pop-culture with art to create colorful, painterly illustrations, posters and portraits.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/melissashipley/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/mshipleyart",
						},
						{
							name: "website",
							link: "https://melissashipley.com/",
						},
					],
				},
				{
					id: 41,
					img: "/assets/img/2023/OTS_MichaelRice.jpg",
					headline: "On the sheet",
					artist: "Michael Rice",
					content:
						"Michael Rice | onefouroh is an artist based in the UK. During the day he’s a graphic designer, but uses any and all free time he has to create new and exciting artwork, while forever fine tuning his skills and knowledge. Since June 2020, Michael has been creating art that focuses on his interests in pop culture and this has led him to opportunities for licensed work, being featured in art books and showcasing his art at comic cons. All my artwork is created through a passion for pop culture and the eagerness to stand out.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/onefouroh/",
						},
						{
							name: "website",
							link: "https://michaelrice.co.uk",
						},
					],
				},
				{
					id: 42,
					img: "/assets/img/2023/OTS_MickaelJournou.jpg",
					headline: "On the sheet",
					artist: "Mickaël Journou",
					content:
						"Mickaël Journou is a French graphic designer and motion designer, working for a small communication agency in Marseille. But his true passion is somewhere else: in his free time, he enjoys creating his own movie and TV show posters. To him, designing a poster is like a puzzle to be solved - moving each picture until everything fits together just right. Hoping to have the chance to work in the film industry someday.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/mikeposters/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/MickaelJournou",
						},
					],
				},
				{
					id: 43,
					img: "/assets/img/2023/OTS_monsieurgordon.jpg",
					headline: "On the sheet",
					artist: "monsieurgordon",
					content:
						"monsieurgordon is designer with a love for 70s era for movie posters. In awe of Saul Bass’ work, its influence on them is obvious : a taste for simplicity, a love for empty blocks, silhouettes, and negative space. They have a rule: if it’s not working with three or four colours, then they’re doing something wrong. ",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/monsieurgordon",
						},
						{
							name: "twitter",
							link: "https://twitter.com/monsieurgordon",
						},
						{
							name: "website",
							link: "https://www.monsieurgordon.com",
						},
					],
				},
				{
					id: 44,
					img: "/assets/img/2023/OTS_NadaMaktari.jpg",
					headline: "On the sheet",
					artist: "Nada Maktari",
					content:
						"Nada Maktari is a British-Yemeni Architectural Designer and Artist based in London, UK. Nada is fascinated by digital visuals inspired by dystopian and pop cultural themes, she likes to integrate architectural aspects and compositions from popular features in film, developing a style evoking futurism through geometry. Her clients include Bottleneck Gallery, Moor-Art Gallery and The Poster Posse.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/maktariarchitecture/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/nadamaktari",
						},
						{
							name: "website",
							link: "https://www.nadamaktari.com",
						},
					],
				},
				{
					id: 45,
					img: "/assets/img/2023/OTS_NeilDavies.jpg",
					headline: "On the sheet",
					artist: "Neil Davies",
					content:
						"Neil Davies is a freelance illustrator with over a decade experience in editorial, caricature, movie poster and key art. He has been fortunate to work with clients such as The Week Magazine, The Wall Street Journal, The Hollywood Reporter, Arrow Video and Netflix - and others that NDAs currently prevent naming!",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/neildaviesillustration1/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/neildaviesart",
						},
						{
							name: "website",
							link: "https://neildaviesillustration.co.uk",
						},
					],
				},
				{
					id: 46,
					img: "/assets/img/2023/OTS_NeilFraser.jpg",
					headline: "On the sheet",
					artist: "Neil Fraser",
					content:
						"Neil is a multi award winning poster and key artist. Born in High Wycombe (UK) and now residing in Falkirk Scotland Neil grew up desperate to be in the movie business, but a lack of talent for acting, camera work, visual effects and writing led Neil to concentrate on his passion of art, Neil's break in the business came from one simple piece of fan art for viral Shudder hit “HOST”, since then he has worked on over 60 productions from indie films, Book covers, large budget productions right through to helping established directors get funding via pitch decks. Formerly a product designer for Marvel, Warner brothers, Disney and DC among others Neil now splits his time between the daily grind of Corporate design and Poster and Key art.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/neilfrasergraphics/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/neilfraser78",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/Neilfrasergraphics",
						},
						{
							name: "website",
							link: "https://neilfrasergraphics.com",
						},
					],
				},
				{
					id: 47,
					img: "/assets/img/2023/OTS_NeriahMooneegan.jpg",
					headline: "On the sheet",
					artist: "Neriah Mooneegan",
					content:
						"Neriah Mooneegan is a British-Asian artist based in the UK who typically works digitally, focusing on graphic props for films or posters. She has worked in costume within the film industry for Warner Brothers and Lucasfilm but is currently pivoting into the graphics department. Aside from graphics, Neriah also enjoys experimenting with other traditional mediums. She is very hands-on and enjoys creating fantasy/period-based objects.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/neriah_designs",
						},
						{
							name: "twitter",
							link: "https://twitter.com/neriah_designs",
						},
					],
				},
				{
					id: 48,
					img: "/assets/img/2023/OTS_PabloVidal.jpg",
					headline: "On the sheet",
					artist: "Pablo Vidal",
					content:
						"Pablo Vidal is a Spain based illustrator and concept artist, focusing on illustration and poster designs. Having studied Fine Arts, his interest in the pictorial tradition due to the skills acquired after learning traditional techniques such as oil or pastel. Nowadays his style can be described as realistic come from traditional painting techniques to digitally. His clients include Sony, Columbia Pictures, BBC, Red Bull, Zacatrus, Britbox, La Biennale di Venezia, Netflix, and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/pablovidalart/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/paul_uve",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/pablo.vidal.52206/",
						},
						{
							name: "website",
							link: "https://www.pablovidalart.com",
						},
					],
				},
				{
					id: 49,
					img: "/assets/img/2023/OTS_PhilShelly.jpg",
					headline: "On the sheet",
					artist: "Phil Shelly",
					content:
						"Based in Manchester in the UK, Phil Shelly is a freelance illustrator and designer who creates alternative posters for popular films and television shows. Phil’s early work focused largely on minimalist illustration, but over time he has developed and expanded his portfolio to include more detailed and photo-based designs. In 2022, Phil was part of the Poster Posse Protege program. He also worked with multiple directors and producers to create artwork for films entered in a range of film festivals, as well as for films in pre-production.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/philshellycreative",
						},
						{
							name: "twitter",
							link: "https://twitter.com/philshelly",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/philshellycreative",
						},
						{
							name: "website",
							link: "https://www.philshelly.com",
						},
					],
				},
				{
					id: 50,
					img: "/assets/img/2023/OTS_PuckHeroesArt.jpg",
					headline: "On the sheet",
					artist: "PuckHeroes Art",
					content:
						"Gianluca Ferrara, also known by his artist name as PuckHeroes Art, is a graphic designer based in Toronto, Canada who specializes in minimalist vector art. He has an interest in poster art which are based on his favourite films, television shows, musicians, and original concepts.<br><br><b>About the Poster</b><br>Nick Frost was diagnosed with a terminally ill disease and his days were numbered. His father, Jonathan Frost, was the owner of Frost Laboratories which launched “Project Frostbite” as a way to cure Nick’s disease through the use of ice. The ice was meant to be a cure, however, it created a monster named Frostbite.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/puckheroesart",
						},
						{
							name: "twitter",
							link: "https://twitter.com/puckheroesart",
						},
						{
							name: "tiktok",
							link: "https://www.tiktok.com/@puckheroesart?lang=en",
						},
						{
							name: "website",
							link: "https://puckheroesart.com/",
						},
					],
				},
				{
					id: 51,
					img: "/assets/img/2023/OTS_RicardoFerllen.jpg",
					headline: "On the sheet",
					artist: "Ricardo Ferllen",
					content:
						"Ricardo Ferllen is a graphic designer from Granada, Spain. He is focused on movie posters and has collaborated with many companies to create posters and blu-ray covers. He loves designing posters using Key Art, Illustration or 3D Technology. His clients include Warner Music Spain, NSM Records, Raven Banner Entertainment, Legendary Entertainment and other companies.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/rikiege/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/rikiege",
						},
						{
							name: "website",
							link: "https://www.behance.net/RicardoFerllen",
						},
					],
				},
				{
					id: 52,
					img: "/assets/img/2023/OTS_SabrinaOlivera.jpg",
					headline: "On the sheet",
					artist: "Sabrina Olivera",
					content:
						"Sabrina Olivera is a Hispanic artist based in Florida specializing in limited color palettes. With a passion for all things pop culture, she uses her many years of art to create eye-catching illustrations of her favorite works. From her t-shirt design for clients like Hot Topic and Box Lunch or her collaboration with The Quarto Group to create can’t miss coloring books, she can’t wait for what the next year holds!",
					links: [
						{
							name: "instagram",
							link: "http://instagram.com/sabrina_olivera",
						},
						{
							name: "twitter",
							link: "https://twitter.com/JarOfOlives98",
						},
						{
							name: "website",
							link: "https://www.artstation.com/sabrinaoliver",
						},
					],
				},
				{
					id: 53,
					img: "/assets/img/2023/OTS_SamDunn.jpg",
					headline: "On the sheet",
					artist: "Sam Dunn",
					content:
						"Sam Dunn is a freelance Illustrator based in South East London / Kent. She grew up in Hartlepool, a fading seaside town in the far North East of England and spent her weekends exploring the dense forests of the Lake District National Park. She enjoys working on a wide range of projects in music, film, advertising & everything in between. Her work is defined by a detail-oriented, organic style, with a bold use of colours and subtle texturing.",
					links: [
						{
							name: "instagram",
							link: "http://www.instagram.com/xsamdunnx",
						},
						{
							name: "twitter",
							link: "https://twitter.com/samdunn",
						},
						{
							name: "facebook",
							link: "http://facebook.com/samdunnillustration/",
						},
						{
							name: "website",
							link: "https://www.sam-dunn.com",
						},
					],
				},
				{
					id: 54,
					img: "/assets/img/2023/OTS_SamerAlkaabi.jpg",
					headline: "On the sheet",
					artist: "Samer Alkaabi",
					content:
						"Samer Alkaabi is a freelance digital artist based in Iraq, he is a big film, TV and game enthusiast who enjoys creating art for the things he finds passion in. Inspired by concept art and motion, Samer likes to create strong compositions and likes playing with perspective in his work all within frameworks of strong concepts and emotion-evoking ideas, clean and sharp are some of the first things you notice about his work that is mainly done in vector.<br><br>Samer is always working on improving his art and trying new things and in 2022 he managed to begin doing officially licensed work for clients like 2K UK and PosterSpy.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/sameralkaabiart/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Sameralkaabi",
						},
						{
							name: "website",
							link: "https://www.behance.net/sameralkaabi",
						},
					],
				},
				{
					id: 55,
					img: "/assets/img/2023/OTS_SamuelCox.jpg",
					headline: "On the sheet",
					artist: "Samuel Cox",
					content:
						"Samuel Cox is an illustrator from the Isle of Man, focusing on key art/poster work to express his love and admiration for films, tv shows and sports. His strongest style, would be described as the use of pencils and various paint brushes to create a marriage of realism and fantasia in new intriguing compositions. He is yet to have had the pleasure of creating licensed works but is determined and hopeful to work professionally with studios, sports teams, galleries and individuals in the near future to bring his imagination and passion to life.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/samueltheartist/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/SamuelCox28",
						},
						{
							name: "linkedin",
							link: "https://www.linkedin.com/in/samuel-cox-924728185/",
						},
						{
							name: "website",
							link: "https://samuelcox2801.myportfolio.com",
						},
					],
				},
				{
					id: 56,
					img: "/assets/img/2023/OTS_SatchelCouture.jpg",
					headline: "On the sheet",
					artist: "Satchel Couture",
					content:
						"Satchel Couture is an Illustrator and Designer from Massachusetts with a passion for all things pop culture, focusing on Key art for media and Alternative poster designs. Sporting a bold style reminiscent of the illustrated posters of the past, Satchel mixes the old with his own modern touch of new.<br><br> If you wish to work together or just want to say hi  you can get in contact at any of these places.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/satchelcouture/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/SatchelCouture",
						},
						{
							name: "website",
							link: "https://satchelcouture.myportfolio.com",
						},
					],
				},
				{
					id: 57,
					img: "/assets/img/2023/OTS_ScottSaslow.jpg",
					headline: "On the sheet",
					artist: "Scott Saslow",
					content:
						"Scott Saslow is a freelance graphic designer based in Van Nuys, California. His specialities include alternative posters, theatrical key art, home video packaging, and assorted pop culture geekery. He’s also worked as a movie studio file clerk, office temp, background actor, retail worker, and NASA test subject (yes, really!). You can see his work at scottsaslow.com and reach him at scott_saslow@yahoo.com.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/scottsaslow/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/@saslow_scott",
						},
						{
							name: "linkedin",
							link: "https://www.linkedin.com/in/scottsaslow",
						},
						{
							name: "website",
							link: "https://scottsaslow.com",
						},
					],
				},
				{
					id: 58,
					img: "/assets/img/2023/OTS_SimonDelart.jpg",
					headline: "On the sheet",
					artist: "Simon Delart",
					content:
						"Simon Delart is a French illustrator and graphic designer based in Nantes, France. Using vector or digital drawing, he loves to pay tribute to pop culture icons, movies, comic books or video games in his personal work. In France, he officially worked with video games studios such as Ubisoft, Activision Blizzard, Microïds or Warner Bros Games. He is also art director and publisher in the ARTtitude collective. Simon is also part of the Poster Posse collective, which brought him to work frequently for studios like Disney, Marvel, Lucas Film, or Warner Bros.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/s2lart/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/s2lart",
						},
						{
							name: "facebook",
							link: "http://facebook.com/s2lart",
						},
						{
							name: "website",
							link: "https://www.s2lart.com",
						},
					],
				},
				{
					id: 59,
					img: "/assets/img/2023/OTS_SophiaFranco.jpg",
					headline: "On the sheet",
					artist: "Sophia Lauren Franco",
					content:
						"Sophia Lauren Franco, a Mexican American illustrator, is from the Detroit, MI region. She honed her skills and found a deep connection to portraiture and posters while attending the College for Creative Studies. Her creative endeavors combine her love of people with her aptitude for visual storytelling. She aspires to highlight underrepresented narratives through her pieces as she believes they deserve artwork of prominence.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/sophialartista/",
						},
						{
							name: "shop",
							link: "https://society6.com/sophialartista",
						},
						{
							name: "website",
							link: "https://sophialaurenfranco.com/",
						},
					],
				},
				{
					id: 60,
					img: "/assets/img/2023/OTS_StephenCampanella.jpg",
					headline: "On the sheet",
					artist: "Stephen Campanella",
					content:
						"Stephen Campanella is a New York City-based illustrator and graphic designer. Stephen’s true passions lie in his alternative poster design work. His skills in traditional materials (graphite, ink, etc.) are incorporated with the latest digital media techniques to create modern posters with a classic illustrated style. Adorned with realistic portraiture and bold typography, his posters have been featured through galleries, art exhibitions, and online platforms, being sold internationally for editorial use as well as private clients.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/stephencampsart/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/StephenCampsArt",
						},
						{
							name: "website",
							link: "https://stephencampanellaart.com/",
						},
					],
				},
				{
					id: 61,
					img: "/assets/img/2023/OTS_TonyParkash.jpg",
					headline: "On the sheet",
					artist: "Tony Parkash",
					content:
						"Tony Parkash is an Illustrator from England, a self-diagnosed nostalgic with a penchant for alternative film poster and portrait art. He has a passion for pop culture that revolves around film, television and video games.<br><br>His style was born from a disability, leading him away from traditional art and into pixel art. He creates cinematic pixel art centred around iconic characters in pop culture.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/tony_parkash/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/TonyParkash",
						},
					],
				},
				{
					id: 62,
					img: "/assets/img/2023/OTS_TraciYau.jpg",
					headline: "On the sheet",
					artist: "Traci Yau",
					content:
						"Traci Yau is an SF Bay Area based graphic designer and illustrator who studied Graphic Design at the Rhode Island School of Design. Currently working as a freelance graphic designer, she is also shifting her career to where her true passion lies: the intersection of design, illustration, and pop culture, in the form of pop culture art and alternative movie poster design.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/echo.base/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/EchoBaseArt",
						},
						{
							name: "website",
							link: "https://www.traciyau.com/",
						},
					],
				},
				{
					id: 63,
					img: "/assets/img/2023/OTS_U037.jpg",
					headline: "On the sheet",
					artist: "U037",
					content:
						"U037 is the working pseudonym of Paul Leon. Rumour says he was left in the wilderness to survive just on his creative skills and a fine liner. An industrial, graphic, digital designer and illustrator, inspired by comics, film, sci fi and the production design drawings of Mead, Cobb and Johnston. He has a personal penchant for neo futurism, cyberpunk and mecha. A polymath he’s worked as a CD on projects that have included transport, film and toy design to more corporate work all for big brand clients globally.<br><br><b>About the movie poster:</b><br>Inspired by 1980’s sci-fi  and the VHS b-movie straight to video productions of the time and their painted posters.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/730ustudio/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/730uart",
						},
						{
							name: "website",
							link: "https://www.excelsior-studios.com/",
						},
					],
				},
				{
					id: 64,
					img: "/assets/img/2023/OTS_VivianLaduch.jpg",
					headline: "On the sheet",
					artist: "Vivian Laduch",
					content:
						"Vivian Laduch is a Germany based Communication & Graphic Designer who studied at the Alsterdamm – School of Visual Arts and htk academy in Hamburg. During his studies he discovered his love for typography and experimental poster design. Now he combines his love for movies and design to create alternate movie posters, often looking for a twist in his design by working with various materials and or tools.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/bart21/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/LaduchVivian",
						},
					],
				},
				{
					id: 65,
					img: "/assets/img/2023/OTS_ZiXu.jpeg",
					headline: "On the sheet",
					artist: "Zi Xu",
					content:
						"Zi Xu is a poster illustrator hailing from China and currently living in the US. She recently did an official poster for John Wick 4, then decided she liked the pose for one of the unused sketches, so she recycled it to use as a self-portrait here.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/ziqqix/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/ziqqix",
						},
					],
				},
				{
					id: 66,
					img: "/assets/img/2023/OTS_ZoeRoseLoMenzo.jpg",
					headline: "On the sheet",
					artist: "Zoe Rose LoMenzo",
					content:
						"Zoe Rose LoMenzo is a pop culture artist, illustrator, and graphic designer from Los Angeles, CA. She is inspired by the illustration styles of comic books and the worlds of her favorite films. Her love for creating pop culture art and her job working on movie posters has led her to pursuing her passion as an alternative movie poster artist.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/zoeroseart",
						},
						{
							name: "twitter",
							link: "https://twitter.com/zoeroseart",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/zoeroseart",
						},
						{
							name: "website",
							link: "https://www.zoeroseart.com",
						},
					],
				},
				{
					id: 67,
					img: "/assets/img/2023/OTS_Genzo.jpg",
					headline: "On the sheet",
					artist: "Zoltán Kovács",
					content:
						"Zoltán Kovács, aka „Genzo” is a graphic designer/digital artist from Romania. His main fields of interest are movies, comic books and pop culture in general, that’s why he started making alternative posters to express his love and fandom while also practicing his craft. Currently he works as a designer/photographer at a furniture company in his hometown and in his free time he creates posters as a hobby. He designed his first alternative movie poster in 2014, and since then he’s made over two hundred pieces. All his artworks are personal, unofficial and non-commercial projects inspired only by his enthusiasm for movies.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/genzoinsta/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/genzothings",
						},
					],
				},
				{
					id: 68,
					img: "/assets/img/2023/OTS_JusticeGage.jpg",
					headline: "On the sheet",
					artist: "Justice Gage",
					content:
						"A Clio Award-winning Art Director, Justice develops and adapts key art in the theatrical and streaming space for clients such as Netflix, Disney+, and NBCUniversal. At the very basic level, though, he is a creative problem-solver captivated by the most influential force of modern humanity: words. Letterforms that tell a story in a visually disruptive way ignite a fire inside him, sparking a burning passion for typography and language.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/justjusticegage",
						},
						{
							name: "twitter",
							link: "https://twitter.com/justjusticegage",
						},
						{
							name: "linkedin",
							link: "https://www.linkedin.com/in/iamjusticegage",
						},
						{
							name: "website",
							link: "https://www.iamjusticegage.com",
						},
					],
				},
				{
					id: 69,
					img: "/assets/img/2023/OTS_mutant101.jpg",
					headline: "On the sheet",
					artist: "Mutant 101",
					content:
						"Before we get into this, you should know the person behind ‘Mutant 101®’, Spence, is a Canadian. He (I’m obviously typing this) is also a freelance artist with a fantastic family. Working in both digital and traditional/analog media, he’s proud of the fact his style is difficult to capture in one sentence. His clients include Netflix, Paramount, Maximum Effort, 20th Century Studios, Empire Magazine, Showtime, Wrexham FC, and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/mutant101/",
						},
						{
							name: "website",
							link: "https://mutant101.com",
						},
					],
				},
			],
			2022: [
				{
					id: 2,
					img: "/assets/img/2022/camartin_portraitofaladyonfire_1300.jpg",
					headline: "Portrait of a lady on fire",
					artist: "Courtney Autumn Martin",
					content:
						"Courtney Autumn Martin is a freelance illustrator living in Rhode Island. She graduated from the Rhode Island School of Design (RISD) with a degree in illustration and spent 15 years working as an illustrator for children where her work appeared in picture books, book covers, and educational materials. These days she works as a member of The Poster Posse, illustrating pop culture movie posters, portraits, and cover art for clients including Disney and Marvel Studios.",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/camartinart",
						},
						{
							name: "twitter",
							link: "https://twitter.com/camartinart",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/camartinart",
						},
						{
							name: "website",
							link: "https://www.camartinart.com/",
						},
						{
							name: "shop",
							link: "https://www.shopcamartinart.com/",
						},
					],
				},
				{
					id: 1,
					img: "/assets/img/2022/hayleyturnbull_spencer_1300.jpg",
					headline: "Spencer",
					artist: "Haley Turnbull",
					content:
						"Haley Turnbull is a digital artist and poster designer living and working in Toronto, Canada. She has been designing her own alternative movie posters for over a decade and has been working professionally in the film industry since 2020. Outside of digital work, she is accomplished in traditional mediums such as oil painting, drawing, and printmaking, which continue to inform her work. Clients have included Second Sight Films, Intermission Film, and numerous independent filmmakers.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/lescarletwoman/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/lescarletwoman",
						},
						{
							name: "website",
							link: "www.haleyturnbull.com",
						},
					],
				},
				{
					id: 22,
					img: "assets/img/2022/mariasuarezinclan_ladybird_1300.jpg",
					headline: "Lady Bird",
					artist: "Maria Suarez-Inclan",
					content:
						"Maria Suarez-Inclan is a designer and illustrator from Madrid, Spain. She has worked as an Art director and designer across different creative agencies in London and Amsterdam. She is passionate about comic books, coming of age films and pop culture. She has done work for Disney, Sony, Fox, Variety magazine, British film Institute among others.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/m_sinclan",
						},
						{
							name: "twitter",
							link: "https://twitter.com/m_sinclan",
						},
						{
							name: "website",
							link: "http://www.msinclan.com/",
						},
					],
				},
				{
					id: 29,
					img: "assets/img/2022/eileensteinbach_virginsuicides_1300.jpg",
					headline: "The Virgin Suicides",
					artist: "Eileen Steinbach",
					content:
						"Eileen Steinbach is a designer and illustrator from Germany, focusing on key art as well as alternative art to promote films from indie pieces to studio productions. Her style can be described as conceptual minimalism, with strong and evocative ideas being the main focus of both her illustrative and image-based work. Her clients include Disney, Marvel, Pixar, Netflix, 20th Century Studios, Sony Pictures, Shudder, and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/sg_posters",
						},
						{
							name: "twitter",
							link: "https://twitter.com/SG_Posters",
						},
						{
							name: "facebook",
							link: "https://www.facebook.com/sgposters/",
						},
						{
							name: "website",
							link: "http://www.sg-posters.com",
						},
					],
				},
				{
					id: 16,
					img: "assets/img/2022/heathervaughanpyw_1300.jpg",
					headline: "Promising young woman",
					artist: "Heather Vaughan",
					content:
						"Heather Vaughan is an award winning Illustrator whose work has been featured in a wide range of markets, including: Editorial, Advertising, Publishing, Concept Art, and Game Art. She has been the recipient of awards and recognition from publications such as: American Illustration, Communication Arts, and Society of Illustrators Los Angeles. Heather lives and works in Philadelphia PA where she enjoys Wawas, long walks along the Schuylkill, and smelling smells at DiBruno’s. When she's not drawing she is spending time with her partner, their dog, and an ever growing collection of snakes and lizards and tarantulas.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/hvon88",
						},
						{
							name: "twitter",
							link: "https://twitter.com/H_vonn",
						},
						{
							name: "website",
							link: "https://hvonillustration.com",
						},
					],
				},
				{
					id: 25,
					img: "assets/img/2022/nicolerifkin_bande_1300.jpg",
					headline: "Bande à part",
					artist: "Nicole Rifkin",
					content:
						"Nicole Rifkin is an award winning Canadian American illustrator based in  Brooklyn, NY. She graduated from Pratt Institute with a BFA in Communications Design (Illustration) and has an MFA in Illustration from SVA. Since 2014, Nicole has worked for: Apple, The Atlantic, Audible, Buzzfeed, Fast Company, The New Yorker, New York Magazine, The New York Times, NPR, Playboy, Reebok, Showtime, Vice, Variety, The Village Voice, Vulture, and more. Her work has been recognized by American Illustration, a Gold and Silver Medal and published in Society of Illustrators, Communication Arts, Creative Quarterly, Society of Illustrators West, 3x3 and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/nicolerifkin",
						},
						{
							name: "website",
							link: "https://nicolerifkin.com",
						},
					],
				},
				{
					id: 3,
					img: "assets/img/2022/izzyaghahowa_madeline_1300.jpg",
					headline: "Madeline`s Madeline",
					artist: "Izzy Aghahowa",
					content:
						"Izzy Aghahowa is a 19 year old freelance oil painter and graphic designer from Nigeria, who creates colourful, surreal and experimental alternative paintings / movie posters as well as alternative artwork for music projects. Izzy is fresh in their freelance career and currently does mostly commission work for small independent projects.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/izzyaghahowa/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/izzyagh",
						},
						{
							name: "website",
							link: "https://izzyaghahowa.co.uk",
						},
					],
				},
				{
					id: 4,
					img: "assets/img/2022/wintersbone_carlyaf_1300.jpg",
					headline: "Winter`s Bone",
					artist: "Carly A-F",
					content:
						"Carly A-F is a freelance illustrator from the UK with a dramatic, colourful style. Her work is a combination of digital and traditional media; and she illustrates for a wide variety of projects including book covers, posters, ttrpgs, picture books and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/carlydraws.illustration",
						},
						{
							name: "twitter",
							link: "https://twitter.com/carlydraws",
						},
						{
							name: "website",
							link: "https://carlydraws.com",
						},
					],
				},
				{
					id: 5,
					img: "assets/img/2022/lauraracero_dragontattoo_1300.jpg",
					headline: "The girl with the dragon tattoo",
					artist: "Laura Racero",
					content:
						"Laura is a digital crafter based on Madrid (Spain). She believes in the power of images and the story they tell; that’s why she creates (alternative) movie posters, key art and surreal/oneiric images which tell a story and expose a truth, always striving to make them with that artisanship touch that inspires and transcends paper and the passage of time. Her portfolio includes works for clients all over the world, such as Lucasfilm Ltd., Penguin Random House, Del Rey Books, Second Sight Films and the Teatro Real.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/lauraracero/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/lauraracero",
						},
						{
							name: "website",
							link: "www.lauraracero.com",
						},
					],
				},
				{
					id: 6,
					horizontal: true,
					img: "assets/img/2022/sabrinaolivera_fearstreet_1600.jpg",
					headline: "Fear Street",
					artist: "Sabrina Olivera",
					content:
						"Sabrina Olivera is a Hispanic illustrator based in Florida. After her merchandising internship with The Walt Disney Company, Sabrina began taking her art more seriously creating colorful illustrations and oil paintings from her favorite pop culture media. Her previous client work can be seen as tattoo designs for a few people, as well as work with notable companies such as Hot Topic and Box Lunch.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/sabrina_olivera/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/JarOfOlives98",
						},
						{
							name: "website",
							link: "https://sabrinaoliver.artstation.com/",
						},
					],
				},
				{
					id: 7,
					img: "assets/img/2022/lizashumska_suspiria_1300.jpg",
					headline: "Suspiria",
					artist: "Kino Maniac",
					content:
						"Liza (aka Kino Maniac) has been a full-time freelance illustrator and poster artist for the past 5 years. She was born and currently based in Kyiv, Ukraine.\n" +
						"Most of her works are focused on cinema and pop culture. Creating posters is a great way to combine all the things that she enjoys the most - drawing and cinema. It’s a unique kind of art, which can tell you a story without a single word, to convey the mood and atmosphere. She’s had the chance to work on exciting projects for Marvel, Sony, Netflix, Scorpions, DONTNOD, Volga and more.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/kino_maniac/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Liza_Shumska",
						},
						{
							name: "website",
							link: "https://lizashumskayaart.myportfolio.com/work",
						},
					],
				},
				{
					id: 8,
					img: "assets/img/2022/hannahgillingham_littlewomen_1300.jpg",
					headline: "Little Women",
					artist: "Hannah Gillingham",
					content:
						"Hannah Gillingham is a freelance illustrator and artist from the UK, who paints portraits of her favourite characters from pop culture and creates illustrated posters. Working digitally with a revitalised take on digital painting, she creates work that recreates traditional painting techniques digitally, merging both the improvisation of traditional and the versatility of digital media.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/hannahgillinghamm/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Hgillinghamm",
						},
						{
							name: "website",
							link: "https://www.hannahgillingham.com",
						},
						{
							name: "shop",
							link: "https://www.etsy.com/uk/shop/HannahGillinghamm",
						},
					],
				},
				{
					id: 9,
					img: "assets/img/2022/alisonreeve_atomic-blonde_1300.jpg",
					headline: "Atomic Blonde",
					artist: "Alison Reeve",
					content:
						"Alison Reeve is a 21-year-old Illustrator from England. A recent graduate with a Bachelor of Arts in Illustration, Alison is starting out her freelance career, specialising in illustrated movie posters. A big comic book geek, Alison's favourite things to draw are alternative posters for sci fi/fantasy movies, but she loves adapting her style to all kinds of genres.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/_alisonreeve/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/_alisonreeve",
						},
						{
							name: "website",
							link: "https://arillustration.co.uk",
						},
					],
				},
				{
					id: 10,
					img: "assets/img/2022/bailierosenlund_theoldguard_1300.jpg",
					headline: "The Old Guard",
					artist: "Bailie Rosenlund",
					content:
						"Bailie Rosenlund is an award-winning illustrator/comic artist from Canada, working as a designer in animation. Her work tends to revolve around women, nature, and gothic fantasy. Using her strong theatrical imagery, she likes to play off the emotional response to a moment, to represent an image that audiences relate and respond to.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/bailierosenlund",
						},
						{
							name: "twitter",
							link: "https://twitter.com/bailierosenlund",
						},
						{
							name: "website",
							link: "https://www.bailierosenlund.com",
						},
					],
				},
				{
					id: 11,
					img: "assets/img/2022/bethmorris_barbandstar_1600.jpg",
					headline: "Barb and Star Go to Vista Del Mar",
					artist: "Beth Morris",
					horizontal: true,
					content:
						"Beth Morris is a graphic designer based in Cardiff. Having recently completed her MA in Graphic Design, she now works as a freelancer. Her speciality lies in poster and packaging design, primarily influenced by retro design styles. To date, she has worked with ARROW and MASSIVE Cinema, along with designing posters for several short films.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/bam.create/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/_bamcreate",
						},
						{
							name: "website",
							link: "https://www.bamcreate.co.uk/",
						},
						{
							name: "shop",
							link: "https://www.etsy.com/uk/shop/BAMdotcreate",
						},
					],
				},
				{
					id: 12,
					img: "assets/img/2022/bombaliska_contact_1300.jpg",
					headline: "Contact",
					artist: "Bombaliska",
					content:
						"Bombaliska is a french graphic designer based in Paris. After working for different agencies she started freelancing 10 years ago and is now mostly working with independent film distributors. Her clients include: Potemkine Films, Jour 2 Fête and MK2.",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/laure_bombaliska",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Bombaliska",
						},
						{
							name: "website",
							link: "https://www.bombaliska.com",
						},
					],
				},
				{
					id: 13,
					img: "assets/img/2022/cheriechapman_saintmaud_1300.jpg",
					headline: "Saint Maud",
					artist: "Cherie Chapman",
					content:
						"Cherie Chapman is a book designer from the UK. She co-owns a design studio called Chapman & Wilder, alongside Eric C. Wilder. She loves to create alternative movie posters, they are concept led designs and striking to look at. She represents the films skilfully, without giving too much away, in her own unique style. Horror movies are her favourite to work on but her style can be adapted to any genre.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/ccbookdesign",
						},
						{
							name: "twitter",
							link: "https://twitter.com/ccbookdesign",
						},
						{
							name: "website",
							link: "https://www.ccbookdesign.com/posters",
						},
					],
				},
				{
					id: 14,
					img: "assets/img/2022/clairecurtis_portraitofaladyonfire_1300.jpg",
					headline: "Portrait of a lady on fire",
					artist: "Claire Curtis",
					content:
						"Claire Curtis is a UK based film & TV key art designer. She currently works in-house as the lead designer at film agency OC, but enjoys creating AMPʼs in her spare time. Sheʼs worked in collaboration with Universal Pictures, BBC, Britbox, Sony Pictures, Lionsgate, Youtube & Netflix.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/kingsandqueensart",
						},
						{
							name: "twitter",
							link: "https://twitter.com/KQPosters",
						},
					],
				},
				{
					id: 15,
					img: "assets/img/2022/gretaleona_tombraider_1300.jpg",
					headline: "Tomb Raider",
					artist: "Greta Leona",
					content:
						"Greta Leona is a graphic design student based in Freiburg, Germany, with a passion for poster art and experiments with Typography and colors. She works mainly digital but also loves to work analogue. With her age of 20 years she just started her graphic design career but is looking forward to future projects.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/leonagretadesign/",
						},
						{
							name: "twitter",
							link: "https://mobile.twitter.com/leonagretaart",
						},
					],
				},
				{
					id: 17,
					img: "assets/img/2022/jendavies_thewomen_1300.jpg",
					headline: "The Women",
					artist: "Jen Davies",
					content:
						"Jen Davies is a mother / designer / illustrator / movie marketer / make-things-happen-er based in Scotland. She is Creative Director at classic film distributor Park Circus and around the edges juggles creative projects, freelance gigs and bits of book scouting for film and TV. She has designed posters for MGM, Universal, Paramount, Warner Bros., Sony, STUDIOCANAL, Film4 and ITV films plus for various independent producers.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/jendavies.studio",
						},
						{
							name: "twitter",
							link: "https://twitter.com/jendavies",
						},
						{
							name: "website",
							link: "http://www.jendavies.co.uk/site",
						},
					],
				},
				{
					id: 18,
					img: "assets/img/2022/lauraescobar_anewhope_1300.jpg",
					headline: "Star Wars",
					artist: "Laura Escobar",
					content:
						"Laura is a digital illustrator and Product Design leader currently working at Ravn, a Utah-based product development company building high-quality experiences with top talent from Latin America. During her career she has helped dozens of clients worldwide, both as a freelancer and leading teams in software development agencies, working with digital products in a wide array of stages and markets.",
					links: [
						{
							name: "instagram",
							link: "https://instagram.com/laurieesc",
						},
						{
							name: "twitter",
							link: "https://twitter.com/laurieesc",
						},
						{
							name: "website",
							link: "https://laurie.ink",
						},
					],
				},
				{
					id: 19,
					img: "assets/img/2022/makahdes_pariah_1300.jpg",
					headline: "Pariah",
					artist: "Makahdes",
					content:
						"Makahdes is an art director and copywriter with 14 years of experience, both at an entertainment design agency as well as freelance. She has worked on everything from small promotional items to large-scale global campaigns. She primarily creates image-based designs, with an emphasis on concepts and minimalist aesthetics.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/makahdes",
						},
						{
							name: "twitter",
							link: "https://twitter.com/makahdes",
						},
					],
				},
				{
					id: 20,
					img: "assets/img/2022/malone_killbill_1300.jpg",
					headline: "Kill Bill",
					artist: "Malone",
					content:
						"Malone is a French graphic designer and illustrator. She started her career in Paris where she worked for renowned model agencies and photographers. Then she decided to broaden her horizons and move to Southern France, where she now works for a communication agency. Alongside her job, she passionately draws pop culture alternative movies posters and portraits. Lately she has worked on several shows for Hero Complex Gallery and has been published in two Printed in blood artbooks : Aliens and Halloween.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/malone_draws",
						},
						{
							name: "twitter",
							link: "https://twitter.com/malone_draws",
						},
					],
				},
				{
					id: 21,
					img: "assets/img/2022/mariaku_ravenous_1300.jpg",
					headline: "Ravenous",
					artist: "Maria Ku",
					content:
						"Maria Ku is a graphic designer and illustrator from Moscow, Russia, with a passion for all things pop culture. She has worked the movie festival circuit and done gig work for The Washington Post, VOX and Notion. Her work has also been published in several Printed in Blood artbooks.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/grrrenadine",
						},
						{
							name: "twitter",
							link: "https://twitter.com/weird_werewolf",
						},
						{
							name: "website",
							link: "https://www.grrrenadine.com/",
						},
					],
				},
				{
					id: 23,
					img: "assets/img/2022/melaniecuccioli_marieantoinette_1300.jpg",
					headline: "Marie Antoinette",
					artist: "Melanie Cuccioli",
					content:
						"Melanie Cuccioli is a Brooklyn-based illustrator, motion designer, and filmmaker. She enjoys using her illustrative skills to collaborate with directors on posters and graphics. She specializes in entertainment design and currently works with HBO. Her favorite drawing subjects include food, architecture, and unsuspecting New Yorkers.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/mel.cucc",
						},
						{
							name: "twitter",
							link: "https://twitter.com/melaniecuccioli",
						},
						{
							name: "website",
							link: "https://mcuccioli.com/",
						},
					],
				},
				{
					id: 24,
					img: "assets/img/2022/nadamaktari_blackswan_1300.jpg",
					headline: "Black Swan",
					artist: "Nada Maktari",
					content:
						"Nada is a RIBA Architect Assistant and Artist based in London,UK. With a background in architecture, Nada is fascinated by digital visuals inspired by dystopian and pop cultural themes. She likes to challenge herself through integrating architectural aspects and compositions from popular features in film. In addition she recently released Fine-Art prints with Moor-Art Gallery and was selected to exhibit art with PosterSpy at Gallery Different in London. Clients include Moor-Art Gallery and The PosterPosse.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/maktariarchitecture/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/nadamaktari",
						},
					],
				},
				{
					id: 26,
					img: "assets/img/2022/rachelmerrill_vagabond_1300.jpg",
					headline: "Vagabond",
					artist: "Rachel Merrill",
					content:
						'Rachel Merrill is an illustrator and storyboard artist based in New York City, originally from Baton Rouge, Louisiana. Her work has been featured in The New Yorker, Politco EU, and the Ignatz Award nominated "Comics for Choice." She has also designed posters and cover art for Country Artist Martina McBride and OR Books. Most recently, she has completed storyboard and animation work for upcoming commercials and independent films.',
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/ohhhaeee",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Ohhhaeee",
						},
						{
							name: "website",
							link: "https://www.rachelmerrill.net/",
						},
					],
				},
				{
					id: 27,
					img: "assets/img/2022/risarodil_legallyblonde_1300.jpg",
					headline: "Legally Blonde",
					artist: "Risa Rodil",
					content:
						"Risa is a designer, illustrator, and letterer who channels her whimsical side into delightful illustrations. She describes her art style as a vibrant mixture of striking colors with a touch of whimsy and modern retro. Her work mostly ranges from pop culture illustrations to typographic statements, and her distinct style has enabled her to work for notable brands such as Disney, Nick Jr., Hasbro Studios, Facebook, and Marvel.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/risarodil/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/risarodil",
						},
						{
							name: "website",
							link: "http://www.risarodil.com",
						},
						{
							name: "shop",
							link: "https://www.redbubble.com/de/people/risarodil/shop",
						},
					],
				},
				{
					id: 28,
					img: "assets/img/2022/sarahatwa_nomadland_1300.jpg",
					headline: "Nomadland",
					artist: "Sarah Atwa",
					content:
						"Sarah Atwa Is a Digital Artist Based In Egypt, Graduated from Faculty of Arts, Cairo University, but has a huge passion with movies, and everything Pop Culture. With A painterly style, Sarah manages to create compelling pieces that make the viewer look twice.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/redmarker2611",
						},
						{
							name: "website",
							link: "https://redmarker2611.myportfolio.com/",
						},
					],
				},
				{
					id: 30,
					img: "assets/img/2022/sabinebein_chicago_Poster_1300.jpg",
					headline: "Chicago",
					artist: "Sabine Bein",
					content:
						"Sabine Bein is a designer and animator living in Scotland. She creates digital art and animations for a wide range of applications and has nearly 10 years of experience. She works in both 2D and 3D and loves making key art, posters, and animated content for television and movies. She strives to create visuals that capture the feeling and mood of a story and convince the audience to engage with that story - be it reading a book, or watching a tv show or movie.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/motionbean/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/motionbean/",
						},
						{
							name: "website",
							link: "https://www.motionbean.com/",
						},
					],
				},
				{
					id: 31,
					img: "assets/img/2022/roseymorearty_big_1300.jpg",
					headline: "BIG",
					artist: "Rosey Morearty",
					content:
						"Rosey Morearty (she/he) is a non-binary designer focusing on motion graphics in the CA Bay Area. She discovered the movie poster art community on Twitter and loves mixing her passion for film with illustration. One of her favorite personal film projects she has created is a motion graphics piece celebrating the Oscar Best Picture nominees of 2021.",
					links: [
						{
							name: "instagram",
							link: "https://www.instagram.com/artsy_rosey/",
						},
						{
							name: "twitter",
							link: "https://twitter.com/Artsy_Rosey",
						},
						{
							name: "website",
							link: "https://www.roseymorearty.com/",
						},
					],
				},
			],
		},
	},
});
