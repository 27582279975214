var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"vue-card-wrapper col-12 col-sm-6 col-md-4 col-lg-3"},[_c('div',{staticClass:"vue-card",style:(_vm.backgroundImage),on:{"click":_vm.openInfo}},[_c('div',{staticClass:"inner"},[_c('span',[_vm._v(_vm._s(_vm.item.headline))]),_vm._v(" "),_c('span',[_vm._v("by "+_vm._s(_vm.item.artist))])])]),_vm._v(" "),_c('div',{staticClass:"vue-card-info",class:{ active: _vm.isOpen }},[_c('div',{staticClass:"inner",class:{ 'has--horizontal-image': _vm.item.horizontal }},[_c('div',{staticClass:"box is--text"},[_c('i',{staticClass:"bi bi-x-circle-fill close",on:{"click":_vm.closeInfo}}),_vm._v(" "),_c('div',{staticClass:"description"},[_c('h2',{staticClass:"headline text-uppercase"},[_vm._v(_vm._s(_vm.item.headline))]),_vm._v(" "),_c('p',{staticClass:"artist text-uppercase"},[_vm._v("by "+_vm._s(_vm.item.artist))]),_vm._v(" "),_c('p',{domProps:{"innerHTML":_vm._s(_vm.item.content)}}),_vm._v(" "),_c('div',{staticClass:"social"},_vm._l((_vm.item.links),function(link){return _c('a',{attrs:{"href":link.link,"title":_vm.item.artist + '`s ' + link.name.toUpperCase(),"target":"_blank"}},[_c('i',{staticClass:"bi",class:{
									'bi-twitter': link.name === 'twitter',
									'bi-facebook': link.name === 'facebook',
									'bi-instagram': link.name === 'instagram',
									'bi-youtube': link.name === 'youtube',
									'bi-linkedin': link.name === 'linkedin',
									'bi-signpost-2-fill': link.name === 'linktree',
									'bi-house-door-fill': link.name === 'website',
									'bi-bag-fill': link.name === 'shop',
									'bi-tiktok': link.name === 'tiktok',
								}})])}),0)])]),_vm._v(" "),_c('div',{staticClass:"box is--img"},[_c('img',{staticClass:"big-img",attrs:{"src":_vm.item.img,"alt":""}})])])])])}
var staticRenderFns = []

export { render, staticRenderFns }