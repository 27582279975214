<template>
	<div class="vue-card-wrapper col-12 col-sm-6 col-md-4 col-lg-3">
		<div class="vue-card" :style="backgroundImage" v-on:click="openInfo">
			<div class="inner">
				<span>{{ item.headline }}</span>
				<span>by {{ item.artist }}</span>
			</div>
		</div>
		<div class="vue-card-info" v-bind:class="{ active: isOpen }">
			<div class="inner" v-bind:class="{ 'has--horizontal-image': item.horizontal }">
				<div class="box is--text">
					<i class="bi bi-x-circle-fill close" v-on:click="closeInfo"></i>
					<div class="description">
						<h2 class="headline text-uppercase">{{ item.headline }}</h2>
						<p class="artist text-uppercase">by {{ item.artist }}</p>
						<p v-html="item.content"></p>
						<div class="social">
							<a
								:href="link.link"
								:title="item.artist + '`s ' + link.name.toUpperCase()"
								v-for="link of item.links"
								target="_blank"
							>
								<i
									class="bi"
									v-bind:class="{
										'bi-twitter': link.name === 'twitter',
										'bi-facebook': link.name === 'facebook',
										'bi-instagram': link.name === 'instagram',
										'bi-youtube': link.name === 'youtube',
										'bi-linkedin': link.name === 'linkedin',
										'bi-signpost-2-fill': link.name === 'linktree',
										'bi-house-door-fill': link.name === 'website',
										'bi-bag-fill': link.name === 'shop',
										'bi-tiktok': link.name === 'tiktok',
									}"
								>
								</i>
							</a>
						</div>
					</div>
				</div>
				<div class="box is--img">
					<img class="big-img" :src="item.img" alt="" />
				</div>
			</div>
		</div>
	</div>
</template>

<style>
.vue-card .inner:hover {
	-moz-animation: fadeIn 0.7s forwards;
	-ms-animation: fadeIn 0.7s forwards;
	-o-animation: fadeIn 0.7s forwards;
	-webkit-animation: fadeIn 0.7s forwards;
	animation: fadeIn 0.7s forwards;
}

@keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-moz-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-o-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}

@-ms-keyframes fadeIn {
	0% {
		opacity: 0;
	}
	100% {
		opacity: 1;
	}
}
</style>

<script>
export default {
	name: "Card",
	components: {},
	computed: {
		backgroundImage() {
			return `background-image: url(${this.item.img});`;
		},
	},
	mounted() {},
	props: ["item"],
	data() {
		return {
			isOpen: false,
		};
	},
	methods: {
		openInfo() {
			this.isOpen = true;
			this.$root.$emit("stopScrolling");
		},
		closeInfo() {
			this.isOpen = false;
			this.$root.$emit("resumeScrolling");
		},
	},
};
</script>
